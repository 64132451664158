'use client';

import type { JSX } from 'react';

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@ui/components/ui/accordion';

import Section from '@/features/public-layout/components/section';

// <FAQ> component is a lsit of <Item> component
// Just import the FAQ & add your FAQ content to the const faqList arrayy below.

interface FAQItemProps {
	question: string;
	answer: JSX.Element;
}

const faqList: FAQItemProps[] = [
	{
		question: 'Wat gebeurt er tijdens het diepte-interview?',
		answer: (
			<div className='space-y-2 leading-relaxed'>
				Delphi vraagt je de hemd van het lijf, zodat ze jou en je bedrijf écht leert kennen. Aan de hand van een
				uitgebreide vragenlijst laat je weten hoe en waarom jij met je bedrijf bent gestart, waar je je klanten mee
				helpt, wat jouw visie is op je vakgebied en wat jou anders maakt dan anderen in je vakgebied. Maak je geen
				zorgen als je nu denkt, ‘dat weet ik helemaal niet’: dat is precies waar Delphi voor is! Door een reeks aan
				simpele, vanzelfsprekende vragen te beantwoorden en het gewoon eerlijk te zeggen als je het even niet weet, komt
				Delphi precies tot de inhoud die ze nodig heeft om jou verder te helpen met je content strategie én om je
				sparringspartner te zijn voor de stukken die je gaat schrijven.
			</div>
		),
	},
	{
		question: 'Hoe helpt Delphi mij met mijn contentstrategie?',
		answer: (
			<div className='space-y-2 leading-relaxed'>
				Delphi vraagt tijdens het diepte-interview onder andere naar de doelen die jij met je bedrijf wilt bereiken. Een
				‘strategie’ betekent: de manier waarop en de middelen waarmee voorafgestelde doelen bereikt kunnen worden.
				Delphi helpt je om je doelen duidelijk te krijgen én om een plan te maken waarmee je door middel van de juiste
				content je doelen kan bereiken.
			</div>
		),
	},
	{
		question: 'Wat als ik geen inspiratie heb voor mijn content?\n',
		answer: (
			<div className='space-y-2 leading-relaxed'>
				Dat is het mooie aan Delphi: dat overkomt je niet meer! Je kan op elk moment in gesprek met Delphi, en desnoods
				zeg je: “ik heb geen inspiratie.” Vervolgens stelt Delphi je een aantal simpele vragen om input te krijgen voor
				goede content, en voor je het weet komt ze bij je terug met een lading aan content ideeën waarbij je vingers
				zullen kriebelen om aan de slag te mogen.
			</div>
		),
	},
	{
		question: 'Hoe helpt Delphi mij met het schrijven van betere teksten?',
		answer: (
			<div className='space-y-2 leading-relaxed'>
				Door ervoor te zorgen dat je grammatica en spelling klopt, maar óók: door actief mee te denken over sterke
				openingszinnen, originele & begrijpelijke metaforen, slimme call to actions en slotzinnen die een reactie
				oproepen bij de lezer. Je teksten worden beter én leuker!
			</div>
		),
	},
	{
		question: 'Hoe kan Delphi mij helpen om me te onderscheiden van mijn concurrenten?\n',
		answer: (
			<div className='space-y-2 leading-relaxed'>
				Naast véél betere content (waar je concurrenten jaloers op zullen zijn!) heb jij het voordeel van een
				sparringspartner die álles onthoudt wat jij vertelt. Tijdens het diepte-interview, maar ook alle andere
				contactmomenten, speurt Delphi naar bijzonderheden in je antwoorden. Wat komt telkens weer terug? Wat is echt
				iets dat bij jou hoort? Delphi kan het hele internet afspeuren, dus die doet ook simpel de check: wat hoort ze
				wél bij jou, maar ziet ze online amper bij vakgenoten terugkomen en is dus echt een onderscheidend, uniek
				kenmerk van jou en/of jouw werk? Dat waar jij de vinger niet op krijgt of wegwuift omdat je jezelf niet anders
				kent, weet Delphi precies aan te wijzen en onder woorden te brengen. Zodat jullie het samen in de spotlight
				kunnen zetten in al je content.
			</div>
		),
	},
	{
		question: 'Hoe vaak kan ik gebruik maken van Delphi?',
		answer: (
			<div className='space-y-2 leading-relaxed'>
				Zo vaak als je wilt! We werken met abonnementen, en dat betekent dat je tijdens je abonnementsperiode onbeperkt
				gebruik kan maken van alle features die erbij horen. Je kan Delphi dus op elk moment van de dag inzetten als
				sparringpartner voor nieuwe content, vragen om teksten te checken en samen sleutelen aan je contentkalender.
			</div>
		),
	},
	{
		question: 'Is Delphi bedoeld voor startende of ervaren ondernemers?',
		answer: (
			<div className='space-y-2 leading-relaxed'>
				Het maakt niet uit hoeveel ervaring je al hebt als ondernemer of op welk niveau je onderneemt: als jij zelf je
				content schrijft voor social media en daar niet lekker uitkomt, is Delphi voor jou. Het is zowel voor starters
				als ervaren ondernemers een fijne houvast om consistent content te creëren, tot converterende content te komen
				en eindeloos vanuit vernieuwende, frisse invalshoeken te schrijven.
			</div>
		),
	},
	{
		question: 'Hoe werkt het systeem achter de schermen?',
		answer: (
			<div className='space-y-2 leading-relaxed'>
				Delphi gebruikt geavanceerde AI om jouw input te verwerken en om te zetten in op maat gemaakte adviezen. Het
				systeem slaat jouw antwoorden op in een beveiligde database en past machine learning en natural language
				processing toe om je steeds beter te begrijpen. Hierdoor kan Delphi je bij elke interactie beter helpen met
				specifieke, gepersonaliseerde contentstrategieën.
			</div>
		),
	},
	{
		question: 'Hoe is het anders dan ChatGPT?',
		answer: (
			<div className='space-y-2 leading-relaxed'>
				Delphi leert je bedrijf en doelen kennen en geeft gepersonaliseerd advies. ChatGPT biedt algemene antwoorden
				zonder doorlopende context. Daarnaast is Delphi specifiek voor contentcreatie en marketing, met tools voor
				strategie, grammatica, en effectieve communicatie. ChatGPT is breder inzetbaar maar minder gericht. Tenslotte
				wordt Delphi constant verbeterd door het team achter Delphi met expertise in contentmarketing en AI, waardoor je
				altijd de nieuwste inzichten krijgt.
			</div>
		),
	},
];

export default function FAQ() {
	return (
		<Section className='bg-background lg:px-8' id='faq'>
			<div className='mx-auto flex w-full max-w-7xl flex-col gap-12 md:flex-row'>
				<div className='flex basis-1/2 flex-col gap-4 text-left'>
					<p className='font-semibold'>FAQ</p>
					<h3 className='text-primary text-3xl sm:text-4xl'>Veelgestelde vragen</h3>
				</div>

				<Accordion type='single' collapsible className='collapse basis-1/2'>
					{faqList.map((item, i) => (
						<AccordionItem key={`faq-item-${i}`} value={`faq-item-${i}`}>
							<AccordionTrigger>{item.question}</AccordionTrigger>
							<AccordionContent>{item.answer}</AccordionContent>
						</AccordionItem>
					))}
				</Accordion>
			</div>
		</Section>
	);
}
