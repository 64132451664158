'use client';

import Image from 'next/image';

import { generateSizesString } from '@tools/utils/image-sizes/image-sizes';
import { scrollToPosition } from '@tools/utils/scroll-to/scroll-to';
import { useTheme } from '@ui/components/layout/theme-controller/theme-controller';
import { Button } from '@ui/components/ui/button';

import Section from '@/features/public-layout/components/section';

export default function Hero() {
	const { resolvedTheme = 'light' } = useTheme();

	return (
		<Section className='items-center justify-center lg:flex-row'>
			<div className='flex flex-col items-center justify-center gap-6 text-center lg:items-start lg:text-left'>
				<h1 className='text-4xl font-bold lg:text-6xl'>
					<span>Content coaching </span>
					<span className='relative ml-4 mt-3 inline-block whitespace-nowrap'>
						<span className='bg-primary absolute -bottom-1 -left-2 -right-2 -top-1 rounded-sm md:-bottom-1 md:-left-4 md:-right-4 md:-top-1'></span>
						<span className='text-primary-foreground relative'>on demand</span>
					</span>
				</h1>
				<p className='block text-2xl font-normal lg:text-3xl'>Jouw strategische assistent in je broekzak</p>
				<p className='text-lg leading-relaxed'>
					Met Delphi heb je altijd frisse inspiratie voor nieuwe content en schrijf je moeiteloos scroll-stoppende en
					meeslepende teksten om nieuwe klanten te trekken!
				</p>
				<Button size='lg' onClick={() => scrollToPosition('signup')}>
					Schrijf je in voor de wachtlijst
				</Button>

				{/*<TestimonialsAvatars priority={true} />*/}
			</div>
			<div className='lg:w-full'>
				<Image
					src={`/images/hero-${resolvedTheme}.png`}
					alt='Hero illustration'
					className='w-full md:ml-auto lg:w-4/5'
					sizes={generateSizesString()}
					priority={true}
					width={400}
					height={400}
				/>
			</div>
		</Section>
	);
}
