'use client';

import Image from 'next/image';
import { Fragment, ReactNode } from 'react';

import { generateSizesString } from '@tools/utils/image-sizes/image-sizes';
import { useTheme } from '@ui/components/layout/theme-controller/theme-controller';
import { Card, CardContent, CardHeader, CardTitle } from '@ui/components/ui/card';

import Arrow from '@/features/public-layout/components/Arrow';
import Section from '@/features/public-layout/components/section';

type Step = {
	title: ReactNode;
	text: ReactNode;
};
const steps: Step[] = [
	{
		title: (
			<>
				In een <span className='font-semibold'>diepte-interview</span>
			</>
		),
		text: (
			<>
				Ze vraagt je het hemd van het lijf zodat ze jou en je bedrijf écht leert kennen, de belangrijkste content
				thema’s binnen jouw bedrijf scherp krijgt en jouw onderscheidende factor ontdekt én onder woorden kan brengen.
			</>
		),
	},
	{
		title: (
			<>
				Als <span className='font-semibold'>content-strateeg</span>
			</>
		),
		text: (
			<>
				Delphi maakt een content kalender waarin ze alle platformen meeneemt waarop jij te vinden wilt zijn, gericht op
				de doelen die jij met je business wilt bereiken. Denk aan likes, reacties, en natuurlijk: potentiële klanten die
				een kennismaking aanvragen!
			</>
		),
	},
	{
		title: (
			<>
				Als <span className='font-semibold'>tekstcorrector</span>
			</>
		),
		text: (
			<>
				Delphi zorgt ervoor dat alle spellingsfoutjes uit je teksten verdwijnen en dat je zinnen lekker lopen, zodat jij
				je lekker kan focussen op de inhoud.
			</>
		),
	},
	{
		title: (
			<>
				Als <span className='font-semibold'>sparringpartner</span>
			</>
		),
		text: (
			<>
				Delphi helpt je om tot originele, authentieke en echt boeiende content te komen. Meeslepende teksten,
				openingszinnen die ervoor zorgen dat mensen stoppen met scrollen en een afsluiting die reacties uitlokt!
			</>
		),
	},
];

// A list of features with a listicle style.
// - Click on a feature to display its description.
// - Good to use when multiples features are available.
// - Autoscroll the list of features (optional).
export default function FeaturesListicle() {
	const { resolvedTheme = 'light' } = useTheme();
	return (
		<Section id='features'>
			<div className='flex flex-col items-center justify-center gap-8 gap-x-[124px] md:flex-row lg:gap-20'>
				<div className='lg:w-full'>
					<Image
						src={`/images/features-${resolvedTheme}.png`}
						alt='Hero illustration'
						className='w-full lg:w-4/5'
						sizes={generateSizesString()}
						width={500}
						height={500}
					/>
				</div>
				<div className='flex flex-col items-center justify-center gap-6 lg:items-start lg:text-left'>
					<div className='flex flex-col gap-4'>
						<p className='self-start text-base font-semibold'>Onze oplossing</p>
						<h2 className='text-primary text-3xl font-extrabold tracking-tight lg:text-5xl'>
							{/* 💡 COPY TIP: Remind visitors about the value of your product. Why do they need it? */}
							Meet Delphi, maak van contentcreatie een eitje en geef je bedrijf een boost
						</h2>
					</div>

					<div className='flex flex-col gap-4'>
						<p className='text-lg'>
							{/* 💡 COPY TIP: Explain how your product delivers what you promise in the headline. */}
							Samen met Delphi sta je er geen seconde alleen voor. Zie haar als een sparringspartner waar je eens in de
							maand uitgebreid mee gaat zitten, maar die je vervolgens ook op elk moment van de dag in je broekzak bij
							je hebt.
						</p>
					</div>
				</div>
			</div>

			<div className='grid grid-cols-1 gap-1 md:mt-10 lg:grid-cols-11'>
				<h3 className='col-span-full mx-auto mb-12 max-w-lg justify-center text-center text-2xl'>
					Delphi is jouw gepersonaliseerde contentcoach, en helpt je:
				</h3>
				{steps.map(({ title, text }, index) => {
					return (
						<Fragment key={`feature-step-${index}`}>
							<Card className='lg:col-span-2'>
								<CardHeader>
									<CardTitle className='text-primary hyphens-auto'>{title}</CardTitle>
								</CardHeader>
								<CardContent>
									<p>{text}</p>
								</CardContent>
							</Card>
							<div className='flex justify-center lg:col-span-1'>
								{index !== steps.length - 1 &&
									(index % 2 === 0 ? (
										<Arrow extraStyle='max-lg:-scale-x-100 lg:-rotate-90' />
									) : (
										<Arrow extraStyle='lg:-scale-x-100 lg:-rotate-90' />
									))}
							</div>
						</Fragment>
					);
				})}
			</div>

			<div className='md:mt-15 flex flex-col items-center justify-center gap-8 gap-x-[124px] md:flex-row lg:gap-20'>
				<div className='flex flex-col items-center justify-center gap-6 lg:basis-1/2 lg:items-start lg:text-left'>
					<div className='flex flex-col gap-4'>
						<p className='self-start text-base font-semibold'>Dit is Delphi</p>
						<h2 className='text-primary text-3xl tracking-tight lg:text-5xl'>
							{/* 💡 COPY TIP: Remind visitors about the value of your product. Why do they need it? */}
							Jouw strategische assistent in je broekzak
						</h2>
					</div>

					<div className='flex flex-col gap-4'>
						<p className='text-lg'>
							{/* 💡 COPY TIP: Explain how your product delivers what you promise in the headline. */}
							Je had het misschien al geraden: Delphi is een chat-bot. Niet zomaar een anonieme algemene bot die het
							internet afstruint om antwoord te geven op je vragen, maar een bot die jou dankzij jullie gesprekken door
							en door leert kennen en daardoor gepersonaliseerd advies kan geven zodat jullie samen moeiteloos unieke,
							leuke én doeltreffende teksten uit jullie mouw schudden.
						</p>
					</div>
				</div>
				<div className='lg:w-full lg:basis-1/2'>
					<Image
						src={`/images/features-2-${resolvedTheme}.png`}
						alt='Hero illustration'
						className='ml-auto w-full lg:w-4/5'
						sizes={generateSizesString()}
						width={400}
						height={400}
					/>
				</div>
			</div>
		</Section>
	);
}
