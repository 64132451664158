import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@ui/lib/utils';

const buttonVariants = cva(
	'inline-flex btn items-center justify-center whitespace-nowrap rounded-md text-sm font-medium disabled:opacity-50 disabled:text-foreground',
	{
		variants: {
			variant: {
				default: 'btn-primary bg-primary text-primary-foreground',
				success: 'btn-success',
				error: 'btn-error',
				secondary: 'btn-secondary text-secondary-foreground',
				glass: 'btn-glass',
				destructive: 'btn-error',
				outline: 'btn-outline ',
				ghost: 'btn-ghost',
				link: 'btn-link',
				circle: 'btn-circle',
			},
			size: {
				default: 'h-10 px-4 rounded-sm py-2',
				sm: 'h-9 rounded-sm px-3',
				lg: 'h-11 rounded-lg px-8',
				icon: 'size-12',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	}
);

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, asChild = false, ...props }, ref) => {
		const Comp = asChild ? Slot : 'button';
		return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
	}
);
Button.displayName = 'Button';

export { Button, buttonVariants };
