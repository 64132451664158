'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button } from '@ui/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@ui/components/ui/form';
import { Input } from '@ui/components/ui/input';

import { addLead } from '@/app/actions';
import { insertLeadSchema, Lead, NewLead } from '@/lib/database/leads';

type FormStatus = {
	status: 'success' | 'error' | '';
	message: string;
};

export default function NewLeadForm() {
	const [formState, setFormState] = useState<FormStatus>({ status: '', message: '' });

	const form = useForm<Lead>({
		mode: 'onBlur',
		resolver: zodResolver(insertLeadSchema),
		defaultValues: {
			firstName: '',
			lastName: '',
			email: '',
		},
	});

	const onSubmit = useCallback(async (lead: NewLead) => {
		try {
			const response = await fetch('/api/leads', {
				method: 'POST',
				body: JSON.stringify({ ...lead }),
			});

			if (!response.ok) {
				const errorResponse = await response.json();
				if (errorResponse.errors) {
					errorResponse.errors.forEach((error: any) => {
						form.setError(error.field, {
							type: 'manual',
							message: error.message,
						});
					});
				}
			}

			await addLead(lead);
			setFormState({ status: 'success', message: 'We hebben je gegevens opgeslagen 🎉' });
			window?.open('https://forms.gle/BFEBQrH5HgiFm3uH6', '_blank');
		} catch (e: any) {
			setFormState({ status: 'error', message: 'Er is helaas iets mis gegaan ❌' });
		}
	}, []);

	return (
		<Form {...form}>
			<form id='new-lead-form' onSubmit={form.handleSubmit(onSubmit)} className='w-full'>
				<fieldset className='grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2 sm:gap-y-8'>
					<FormField
						control={form.control}
						name='firstName'
						render={({ field, formState: { errors } }) => (
							<FormItem className='col-span-full sm:col-span-1'>
								<FormLabel>Voornaam</FormLabel>
								<FormControl>
									<Input
										variant={errors.firstName ? 'error' : 'bordered'}
										autoComplete='off'
										placeholder='Zara'
										{...field}
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>

					<FormField
						control={form.control}
						name='lastName'
						render={({ field, formState: { errors } }) => (
							<FormItem className='col-span-full sm:col-span-1'>
								<FormLabel>Achternaam</FormLabel>
								<FormControl>
									<Input
										variant={errors.lastName ? 'error' : 'bordered'}
										autoComplete='off'
										placeholder='Janssen'
										{...field}
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>

					<FormField
						control={form.control}
						name='email'
						render={({ field, formState: { errors } }) => (
							<FormItem className='col-span-full'>
								<FormLabel>Email</FormLabel>
								<FormControl>
									<Input
										type='email'
										variant={errors.email ? 'error' : 'bordered'}
										autoComplete='off'
										placeholder='zara@janssen.coach'
										{...field}
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>

					<div className='col-span-full mt-4'>
						<Button size='lg' type='submit' className='w-full' disabled={!form.formState.isValid}>
							Aanmelden
						</Button>
					</div>
					{['error', 'success'].includes(formState.status) && (
						<div className='col-span-full text-center'>
							<p className='text-sm font-medium'>{formState.message}</p>
						</div>
					)}
				</fieldset>
			</form>
		</Form>
	);
}
