import { HTMLAttributes, PropsWithChildren } from 'react';

import { cn } from '@ui/lib/utils';

interface SectionProps extends HTMLAttributes<HTMLElement> {}

export default function Section({ children, className, ...props }: PropsWithChildren<SectionProps>) {
	return (
		<section {...props} className={cn(className, 'container flex flex-col gap-8 py-12 lg:gap-20 lg:py-20')}>
			{children}
		</section>
	);
}
