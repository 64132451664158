import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@ui/lib/utils';

const inputVariants = cva('input w-full', {
	variants: {
		variant: {
			default: '',
			bordered: 'input-bordered',
			ghost: 'input-ghost',
			primary: 'input-primary',
			secondary: 'input-secondary',
			accent: 'input-accent',
			info: 'input-info',
			success: 'input-success',
			warning: 'input-warning',
			error: 'input-error',
		},
		fieldSize: {
			default: 'input-md',
			xs: 'input-xs',
			sm: 'input-sm',
			lg: 'input-lg',
		},
	},
	defaultVariants: {
		variant: 'default',
		fieldSize: 'default',
	},
});

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, variant, fieldSize, type, ...props }, ref) => {
		return (
			<input
				type={type}
				className={cn(inputVariants({ variant, fieldSize, className }), 'placeholder:text-foreground/50')}
				ref={ref}
				{...props}
			/>
		);
	}
);
Input.displayName = 'Input';

export { Input, inputVariants };
